import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import EyeButton from "../components/base/eye-button";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { textHash, textSalt } from "../utils";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import useWindowSize from "../hooks/useWindowSize";

import { AiFillQuestionCircle } from "react-icons/ai";

export default function UserVerify() {
  const { t } = useTranslation();
  console.log("process.env.REACT_APP_SECRET_KEY", process.env.REACT_APP_SECRET_KEY);
  console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);

  const [checked, setChecked] = useState(false);

  const { width } = useWindowSize();

  const [loader, setLoader] = useState(false);
  const [birthDate, setBirthDate] = useState("");
  const [tcno, setTcno] = useState("");
  const [addres, setAddres] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [showTCInfo, setShowTCInfo] = useState(false);
  const [showAddresInfo, setShowAddresInfo] = useState(false);

  const [showPasswor2, setShowPassword2] = useState(false);
  const [showPasswor3, setShowPassword3] = useState(false);
  const [chageStart, setChangeStart] = useState(false);
  const [clientInfos, setClientInfos] = useState({});

  const [id, setId] = useState(null);

  const [rules, setRules] = useState({
    upper: false,
    number: false,
    symbol: false,
    length: false,
  });

  useEffect(() => {
  console.log("loader", loader);
  console.log("rules", rules);
  console.log("chageStart", chageStart);
  console.log("clientInfos", clientInfos);
  }, [loader, rules, chageStart, clientInfos]);
  let { code } = useParams();

  const verifyCode = async () => {
    const saltCode = textSalt(code);
    const explodeCode = saltCode.split("_");

    const lastElement = explodeCode[explodeCode.length - 1];

    if (lastElement === process.env.REACT_APP_SECRET_KEY) {
      const email = explodeCode[0];
      const id = explodeCode[1];
      setId(id);
      const result = await axios.get(
        process.env.REACT_APP_API_URL + "clients/verify-control/" + id
      );
      const data = result.data;

      if (data && data.status && data.data.password_change === false) {
        setClientInfos(data.data);
      } else {
        window.location.href = "/";
      }
    } else {
      alert("Invalid code");
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (password) {
      setRules({
        upper: /[A-Z]/.test(password),
        number: /[0-9]/.test(password),
        symbol: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password),
        length: password.length >= 8,
      });
      setChangeStart(true);
    }
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checked) {
      toast.error("KVKK Aydınlatma Metni'ni Onaylayınız");
    } else {
      setLoader(true);

      if (id) {
        let saveStatus = true;

        if (birthDate.trim().length === 0) {
          toast.error(t("user_verify.birthDateRequired"));
          saveStatus = false;
        }

        if (tcno.trim().length === 0) {
          toast.error(t("user_verify.tcnoRequired"));
          saveStatus = false;
        }

        if (tcno.trim().length !== 11) {
          toast.error(t("user_verify.tcnoLength"));
          saveStatus = false;
        }

        if (addres.trim().length === 0) {
          toast.error(t("user_verify.addres_lenght"));
          saveStatus = false;
        }

        if (isNaN(tcno)) {
          toast.error(t("user_verify.tcnoNumber"));
          saveStatus = false;
        }

        if (password.trim().length === 0) {
          toast.error(t("user_verify.passwordRequired"));
          saveStatus = false;
        }

        if (saveStatus) {
          const data = {
            birth_date: birthDate,
            gender: gender,
            password: password,
            tc: tcno,
            addres: addres,
          };

          const response = await axios.put(
            process.env.REACT_APP_API_URL + "clients/verify/" + id,
            data
          );

          if (response.data.status) {
            toast.success("İşlem başarılı. Yönlendiriliyorsunuz...");
            setTimeout(() => {
              window.location.href = "/giris-yap";
            }, 1200);
          } else {
            toast.error(response.data.message);
            setLoader(false);
          }
        }
      } else {
        window.location.href = "/";
      }
    }
  };

  useEffect(() => verifyCode(), [code]);

  return (
    <Layout>
      <div className="bg-gray-200 w-full md:py-20 py-10 px-5">
        <div className="p-5 md:w-1/3 w-full bg-white shadow-2xl rounded-2xl  mx-auto">
          <h3 className="text-main font-bold text-lg mb-2">
            {t("user_verify.head_title")}
          </h3>
          <p className="mb-3">
            {" "}
            {t("user_verify.head_text").replace(
              "{name}",
              clientInfos?.name_surname || ""
            )}
          </p>
          <form onSubmit={handleSubmit}>
            <div className=" flex flex-wrap flex-col -mx-3 mb-4 px-3 rounded-xl">
              <label className="block  tracking-wide text-textGrey text-base font-semibold  mb-2">
                {t("user_verify.birth_date")}
                <span className="text-red-600 text-lg">*</span>
              </label>

              <div className="relative">
                <input
                  className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3  leading-tight focus:outline-none focus:border-primary"
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  name="birth_date"
                  value={birthDate}
                  required
                  onChange={(e) => setBirthDate(e.target.value)}
                />
              </div>
            </div>

            <div className=" flex flex-wrap flex-col -mx-3 mb-4 px-3 rounded-xl">
              <label className="block  tracking-wide text-textGrey text-base font-semibold  mb-2 flex items-center justify-between relative">
                <div>
                  {t("user_verify.tcno")}
                  <span className="text-red-600 text-lg">*</span>
                </div>
                <span
                  className={cn([
                    "text-main cursor-pointer hover:opacity-70 ",
                    width > 800 && "user-verify-tc-info",
                  ])}
                  onClick={() => setShowTCInfo(!showTCInfo)}
                >
                  <AiFillQuestionCircle />
                </span>
                <div
                  className={cn([
                    "bg-gray-900 px-3 py-2 rounded text-white text-sm absolute left-40 -top-14 ",
                    width > 800 ? "hidden" : !showTCInfo ? "hidden" : "",
                  ])}
                  dangerouslySetInnerHTML={{
                    __html: t("user_verify.tc_info"),
                  }}
                />
              </label>

              <div className="relative">
                <InputMask
                  className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3  leading-tight focus:outline-none focus:border-primary"
                  type="text"
                  min={11}
                  max={11}
                  alwaysShowMask
                  mask="99999999999"
                  maskchar={11}
                  name="tc"
                  value={tcno}
                  required
                  onChange={(e) => {
                    setTcno(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className=" flex flex-wrap flex-col -mx-3 mb-4 px-3 rounded-xl">
              <label className="block  tracking-wide text-textGrey text-base font-semibold  mb-2">
                {t("user_verify.gender")}
              </label>

              <div className="relative">
                <select
                  className="block w-full  text-gray-700 border rounded py-3 px-4 mb-3  leading-tight focus:outline-none focus:border-primary"
                  name="gender"
                  defaultValue={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">{t("select")}</option>
                  <option value={t("genders.women")}>
                    {t("genders.women")}
                  </option>
                  <option value={t("genders.man")}>{t("genders.man")}</option>
                  <option value={t("genders.not_specify")}>
                    {t("genders.not_specify")}
                  </option>
                </select>
              </div>
            </div>

            <div className=" flex flex-wrap flex-col -mx-3 mb-4 px-3 rounded-xl">
              <label className="block  tracking-wide text-textGrey text-base font-semibold  mb-2 flex items-center justify-between relative">
                <div>
                  {t("addres")}
                  <span className="text-red-600 text-lg">*</span>
                </div>
                <span
                  className={cn([
                    "text-main cursor-pointer hover:opacity-70 ",
                    width > 800 && "user-verify-tc-info",
                  ])}
                  onClick={() => setShowAddresInfo(!showAddresInfo)}
                >
                  <AiFillQuestionCircle />
                </span>
                <div
                  className={cn([
                    "bg-gray-900 px-3 py-2 rounded text-white text-sm absolute left-40 -top-14 ",
                    width > 800 ? "hidden" : !showAddresInfo ? "hidden" : "",
                  ])}
                  dangerouslySetInnerHTML={{
                    __html: t("user_verify.addres_info"),
                  }}
                />
              </label>

              <div className="relative">
                <input
                  className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-primary"
                  type=""
                  placeholder={t("addres")}
                  name="addres"
                  value={addres}
                  onChange={(e) => setAddres(e.target.value)}
                />
              </div>
            </div>

            <div className=" flex flex-wrap flex-col -mx-3 mb-4 px-3 rounded-xl">
              <label className="block  tracking-wide text-textGrey text-base font-semibold  mb-2">
                {t("password_form_new_password")}
                <span className="text-red-600 text-lg">*</span>
              </label>

              <div className="relative">
                <input
                  className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3  leading-tight focus:outline-none focus:border-primary"
                  type={showPasswor2 ? "text" : "password"}
                  placeholder={t("password_form_new_password")}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <EyeButton
                  show={showPasswor2}
                  onClick={() => setShowPassword2(!showPasswor2)}
                />
              </div>
            </div>
            <div className=" flex flex-wrap flex-col -mx-3 mb-4 px-3 rounded-xl">
              <label className="block  tracking-wide text-textGrey text-base font-semibold  mb-2">
                {t("password_form_confirm_password")}
                <span className="text-red-600 text-lg">*</span>
              </label>

              <div className="relative">
                <input
                  className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-primary"
                  type={showPasswor3 ? "text" : "password"}
                  placeholder={t("password_form_confirm_password")}
                  name="confirmPassword"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                />

                <EyeButton
                  show={showPasswor3}
                  onClick={() => setShowPassword3(!showPasswor3)}
                />
              </div>
            </div>
            <div className="mx-3 mb-4  ">
              <p className="-mx-3 text-main font-semibold mb-2">
                {t("password_careful")}
              </p>
              <ul className="list-disc px-3 mx-3 text-main font-semibold ">
                <li
                  className={
                    chageStart
                      ? rules.length
                        ? "text-primary"
                        : "text-red-500"
                      : "text-main"
                  }
                >
                  {t("password_text_1")}
                </li>
                <li
                  className={
                    chageStart
                      ? rules.upper
                        ? "text-primary"
                        : "text-red-500"
                      : "text-main"
                  }
                >
                  {t("password_text_2")}
                </li>
                <li
                  className={
                    chageStart
                      ? rules.number
                        ? "text-primary"
                        : "text-red-500"
                      : "text-main"
                  }
                >
                  {t("password_text_3")}
                </li>
                <li
                  className={
                    chageStart
                      ? rules.symbol
                        ? "text-primary"
                        : "text-red-500"
                      : "text-main"
                  }
                >
                  {t("password_text_4")}
                </li>
              </ul>
            </div>

            <div className=" flex items-center mb-3">
              <input
                type="checkbox"
                name="terms"
                checked={checked}
                onChange={() => setChecked(!checked)}
                className="mr-2  "
                id="checkbox"
                required
              />
              <label
                dangerouslySetInnerHTML={{
                  __html: t("global_kvkk_text"),
                }}
                className="text-sm text-main "
                htmlFor="checkbox"
              />
            </div>

            <button
              disabled={
                loader ||
                !(
                  rules.symbol &&
                  rules.number &&
                  rules.upper &&
                  rules.length &&
                  password === password2
                )
              }
              className={cn([
                "text-white w-full flex items-center justify-center py-2 rounded-lg font-bold duration-75 border-main cursor-pointer text-base bg-main",
                loader ||
                (rules.symbol &&
                  rules.number &&
                  rules.upper &&
                  rules.length &&
                  password === password2)
                  ? "opacity-100"
                  : "opacity-50",
              ])}
            >
              {t("global_save")}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
}
